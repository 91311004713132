import * as Schema from 'generated/graphql/schema';
import { TFunction } from 'i18next';

type Unit = string;
type YAxisLabel = string;

export const determineLabel = <
  T extends {
    config?: Pick<Schema.SensorConfig, 'type' | 'chartTimeScale' | 'sensorUnit' | 'chart'>;
  },
>(
  t: TFunction,
  sensor: T | null,
): [Unit, YAxisLabel] => {
  if (!sensor) {
    const loading = t(['shared:loading'], { defaultValue: 'Loading...' });
    return [loading, loading];
  }

  const {
    type,
    sensorUnit = {
      unit: Schema.Unit.PCS,
      label: 'pcs',
    },
    chartTimeScale,
    chart,
  } = sensor.config || {};

  const { dataFilter } = chart || {};
  const scale = chart?.scale || chartTimeScale || Schema.ChartTimeScale.MINUTE;

  const { unit } = sensorUnit || {};

  const isUnitless = !unit;
  if (isUnitless) {
    return [t('shared:notAvailable', 'N/A'), ''];
  }

  const isMeasurement = type === Schema.SensorType.MEASUREMENT;

  const unitLabel = sensorUnit?.label || (isMeasurement ? '-' : t('units:piecesShort', 'pcs'));
  if (isMeasurement) {
    return [unitLabel, unitLabel];
  }

  switch (dataFilter) {
    case Schema.DataFilter.NONE:
      return [unitLabel, unitLabel];
    case Schema.DataFilter.UPTIME:
      const uptime = t(['shared:uptime'], { defaultValue: 'Uptime' });
      return [uptime, uptime];
  }

  switch (scale) {
    case Schema.ChartTimeScale.DAY:
      return [unitLabel, t(['units:unitPerDay'], { defaultValue: '{{unit}}/day', unit: unitLabel })];
    case Schema.ChartTimeScale.HOUR:
      return [unitLabel, t(['units:unitPerHour'], { defaultValue: '{{unit}}/hour', unit: unitLabel })];
    default:
      return [unitLabel, t(['units:unitPerMinute'], { defaultValue: '{{unit}}/min', unit: unitLabel })];
  }
};
