import * as Schema from 'generated/graphql/schema';

import { Point } from '@/lib/highcharts/config-helpers';
import * as Types from '@/types';

type STOP = Pick<Schema.Stop, 'timeRange'>;
type SAMPLE = Schema.Sample & Point;

const convertToTimeDate = (stop: STOP): Types.TimeDate => ({
  from: stop.timeRange.from ? new Date(stop.timeRange.from) : null,
  to: stop.timeRange.to ? new Date(stop.timeRange.to) : null,
});

export const getManualProcessMinutesProduced = (from: Date, to: Date, stops: STOP[]): number => {
  const stopTimes = stops.map(convertToTimeDate);
  return getMinutesProduced(from, to, stopTimes);
};

const getMinutesProduced = (from: Date, to: Date, stopTimes: Types.TimeDate[]): number => {
  let duration = to.getTime() - from.getTime();
  for (const stop of stopTimes) {
    if ((!stop.to || stop.to > from) && (!stop.from || stop.from < to)) {
      const overlapMs =
        Math.min((stop.to ?? to).getTime(), to.getTime()) - Math.max((stop.from ?? from).getTime(), from.getTime());
      duration -= overlapMs;
    }
  }
  return duration / (1).minutes;
};

export const getManualProcessSamples = (from: Date, to: Date, stops: STOP[], points: number): SAMPLE[] => {
  const result: SAMPLE[] = [];
  const stopTimes = stops.map(convertToTimeDate);

  const duration = to.getTime() - from.getTime();
  let interval = Math.floor(duration / points);
  interval = interval > (30).seconds ? interval : (30).seconds;

  for (let sampleStartMs = from.getTime(); sampleStartMs < to.getTime(); sampleStartMs += interval) {
    const sampleStart = new Date(sampleStartMs);
    const sampleEnd = new Date(sampleStartMs + interval - 1);
    result.push(createSample(sampleStart, sampleEnd, getMinutesProduced(sampleStart, sampleEnd, stopTimes)));
  }

  return result;
};

const createSample = (from: Date, to: Date, value: number): SAMPLE => {
  return {
    timeRange: {
      from: from.toISOString(),
      to: to.toISOString(),
    },
    data: {
      accValue: value,
      count: 1,
      minValue: value,
      maxValue: value,
    },
  };
};
