export class AlarmId {
    constructor(sk) {
        this.sk = sk;
    }
    get rawSK() {
        return this.sk;
    }
    get alarmUUID() {
        return this.sk.startsWith('invalidated|') ? this.sk.split('|')[2] : this.sk;
    }
    get invalidatedDate() {
        const result = this.sk.startsWith('invalidated|') ? this.sk.split('|')[1] : null;
        return result !== null && Date.parse(result) ? new Date(result) : null;
    }
}
