import { NoSsr } from '@mui/material';
import { Theme } from '@mui/material/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsExporting from 'highcharts/modules/exporting';
import { isEqual } from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import OverlaySpinner from '@/components/loading/overlay-spinner';
import { WithUtils, withUtils } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import * as Types from '@/types';

if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
  HighchartsExporting(Highcharts);
  HighchartsDrilldown(Highcharts);
}

const styles = (theme: Theme) => ({
  hideOverflow: {
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      // Widen out the chart on small screens, so we can better see the data.
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-1.5),
    },
  },
});

const shouldUpdate = (
  prevProps: UpdatingProperties,
  nextProps: Properties,
  _prevState: State,
  _nextState?: State,
  aggressivelyMinimizeRendering: boolean = false,
): boolean => {
  if (prevProps.loading !== nextProps.loading) {
    return true;
  }
  if (prevProps.error !== nextProps.error) {
    return true;
  }
  // We do a comparison of the supplied compareForUpdate object, to see
  // if anything has changed.
  if (!isEqual(prevProps?.compareForUpdate, nextProps?.compareForUpdate)) {
    return true;
  }
  if (aggressivelyMinimizeRendering) {
    return false;
  }
  return true;
};

const transferUpdatingPropsToState = (props: Properties): UpdatingProperties => {
  return {
    compareForUpdate: props.compareForUpdate,
    loading: props.loading,
    error: props.error,
  };
};

interface UpdatingProperties {
  // Minimize rerendering of the Highcharts graph.
  compareForUpdate?: unknown;
  loading?: boolean;
  error?: boolean;
}

interface NonUpdatingProperties {
  configOptions?: Types.HighchartsConfig;
  configFn?: (cfg: any) => Types.HighchartsConfig;
  errorMsg?: string;
  options?: Types.HighchartsOptions;
  chartRef?: React.Ref<Types.ReactHighchartsElement>;
  hideChart?: boolean;
  aggressivelyMinimizeRendering?: boolean;
  onChartCreated?: (chart: Highcharts.Chart) => void;
}

type Properties = UpdatingProperties & NonUpdatingProperties;
type ExtendedProperties = Properties & WithStyles<typeof styles> & WithTranslation & WithUtils;

interface State {
  config: Types.HighchartsConfig;
  // We need to mirror these properties in the state, in order to minimize the work
  // that `getDerivedStateFromProps` needs to do.
  updatingProps: UpdatingProperties;
}

// FIXME: This is used to help redraw the chart on printing. This may not be
// necessary at all with a rewrite, if so apply it using hooks to easily clean up
// on on dismount.
const mediaQueryList = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');

class HighchartsGraph extends React.Component<ExtendedProperties, State> {
  readonly state: State = {
    config: {},
    updatingProps: {},
  };

  chartRef: React.RefObject<Types.ReactHighchartsElement> = React.createRef<Types.ReactHighchartsElement>();

  printListener = () => this.getChart()?.reflow();

  constructor(props: ExtendedProperties) {
    super(props);

    const isRTL = ['he'].some((lang) => this.props.i18n.language === lang);

    const rtlOptions = {
      tooltip: {
        style: {
          direction: 'rtl',

          textAlign: 'right',
        },
        useHTML: true,
      },
      xAxis: {
        labels: {
          style: {
            direction: 'rtl',
            textAlign: 'center',
          },
          useHTML: true,
        },
        title: {
          style: {
            direction: 'rtl',
            textAlign: 'center',
          },
          useHTML: true,
        },
      },
      yAxis: {
        title: {
          style: {
            direction: 'rtl',
            textAlign: 'center',
          },
          useHTML: true,
        },
      },
      legend: {
        rtl: true,
        style: {
          direction: 'rtl',
          textAlign: 'right',
        },
        useHTML: true,
      },
    };

    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: this.props.theme.typography.fontFamily,
        },
      },
      lang: {
        months: this.props.utils
          .getMonthArray(new Date(2020, 1, 1))
          .map((month) => this.props.utils.format(month, 'month')),
        weekdays: this.props.utils.formatWeekdays('EEEE'),
        shortMonths: this.props.utils
          .getMonthArray(new Date(2020, 1, 1))
          .map((month) => this.props.utils.format(month, 'monthShort')),
      },
      global: {
        useUTC: false,
      },
      ...(isRTL ? rtlOptions : {}),
      ...(this.props.options || {}),
    });
    this.state.updatingProps = transferUpdatingPropsToState(props);
  }

  componentWillUnmount() {
    if (mediaQueryList && 'removeEventListener' in mediaQueryList) {
      mediaQueryList.removeEventListener('change', this.printListener);
    }

    this.setState({
      updatingProps: {
        ...transferUpdatingPropsToState(this.props),
        compareForUpdate: '',
      },
    });
  }

  componentDidMount() {
    if (mediaQueryList && 'addEventListener' in mediaQueryList) {
      mediaQueryList.addEventListener('change', this.printListener);
    }
  }

  componentDidUpdate(_: unknown, __: unknown) {
    this.setChartLoadingOrError(this.props.loading, this.props.error);
  }

  /**
   * `getDerivedStateFromProps` is mainly used to calculate if we need to rerun the configuration
   * generation function, `configFn`, or if we can save some processing and skip it.
   */
  static getDerivedStateFromProps(nextProps: Properties, prevState: State) {
    const shouldDerive = shouldUpdate(
      prevState.updatingProps,
      nextProps,
      prevState,
      undefined,
      nextProps.aggressivelyMinimizeRendering,
    );
    if (shouldDerive && nextProps.configOptions && nextProps.configFn) {
      // This ensures we only run the `configFn` once, either after updatingProps are both updated
      // or in the case that they don't exist.
      if (
        (nextProps.compareForUpdate !== undefined && prevState.updatingProps?.compareForUpdate !== undefined) ||
        (!nextProps.compareForUpdate === undefined && !prevState.updatingProps?.compareForUpdate === undefined)
      ) {
        return {
          ...prevState,
          config: nextProps.configFn(nextProps.configOptions),
          updatingProps: transferUpdatingPropsToState(nextProps),
        };
      }
    }

    return {
      ...prevState,
      updatingProps: transferUpdatingPropsToState(nextProps),
    };
  }

  shouldComponentUpdate(nextProps: ExtendedProperties, nextState: State) {
    return shouldUpdate(this.props, nextProps, this.state, nextState, nextProps.aggressivelyMinimizeRendering);
  }

  setChartLoadingOrError = (loading: boolean | undefined, error: boolean | undefined) => {
    const chart = this.getChart();
    if (chart) {
      if (error && !this.props.hideChart) {
        chart.showLoading(
          this.props.errorMsg ??
            this.props.t(['shared:errorOcurredTryReloading'], {
              defaultValue: 'An error occurred! Please try reloading the page.',
            }),
        );
      } else if (loading && !this.props.hideChart) {
        chart.showLoading(this.props.t(['shared:loadingFromServer'], { defaultValue: 'Loading data from server...' }));
      } else {
        chart.hideLoading();
      }
    }
  };

  getChart = () => {
    if (typeof this.props.chartRef === 'function') {
      return;
    }

    return ((this.props.chartRef || this.chartRef)?.current as any)?.chart;
  };

  public render() {
    const { classes, loading, error, configFn = Function.prototype, configOptions, chartRef, hideChart } = this.props;
    const { config } = this.state;

    if (hideChart) {
      return null;
    }

    // Highcharts for some reason does not use the responsive properties
    // on the initial render, so set the height manually, so the chart does not jump around.
    // A fix for https://github.com/BlackbirdHQ/meta/issues/1607
    const noOfVisibleLegends = Object.values(configFn(configOptions).series).reduce(
      (sum: number, serie: any) => (serie.showInLegend || serie.dataLabels?.enabled ? sum + 1 : sum),
      0,
    );
    const legendsHeight = noOfVisibleLegends * 16;

    const smallScreen = (180 + legendsHeight).toString();
    const largeScreen = configOptions?.height ?? smallScreen;
    const smallScreenCutoff = 536;
    const chartHeight = window.innerWidth < smallScreenCutoff ? smallScreen : largeScreen;

    const defaultConfig = {
      title: false,
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      chart: {
        height: chartHeight,
      },
    };

    let highchartsConfig = {
      ...defaultConfig,
      ...configFn(configOptions),
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: smallScreenCutoff,
            },
            chartOptions: {
              chart: {
                height: smallScreen,
              },
            },
          },
        ],
      },
      plotOptions: {
        ...(config?.plotOptions ?? {}),
        series: {
          ...(config?.plotOptions?.series ?? {}),
          turboThreshold: 0,
        },
      },
    };

    highchartsConfig.chart.height = chartHeight;

    if (loading || error) {
      highchartsConfig = {
        ...highchartsConfig,
        plotOptions: {
          ...highchartsConfig.plotOptions,
          series: {
            animation: false,
          },
        },
      };
    }

    return (
      <NoSsr fallback={<OverlaySpinner size={35} />}>
        <HighchartsReact
          immutable
          options={highchartsConfig}
          highcharts={Highcharts}
          ref={(chartRef as any) || this.chartRef}
          domProps={{ className: classes.hideOverflow }}
          callback={this.props.onChartCreated}
        />
      </NoSsr>
    );
  }
}

export const Instance = Highcharts;
const enhance = compose<ExtendedProperties, Properties>(
  withTranslation(['shared']),
  withStyles(styles, { withTheme: true }),
  withUtils,
);
export const Graph = enhance(HighchartsGraph);
