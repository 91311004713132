import { CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (_theme: Theme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .3)',
  },
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

interface Properties {
  size?: number;
}

class LoadingSpinnerOverlay extends React.Component<Properties & WithStyles<typeof styles>, {}> {
  public render() {
    const { classes, size } = this.props;

    return (
      <div className={classes.overlay}>
        <div className={classes.center}>
          <CircularProgress color="primary" size={size} />
        </div>
      </div>
    );
  }
}
const enhance = compose<unknown, Properties>(withStyles(styles, { withTheme: true }));
export default enhance(LoadingSpinnerOverlay as React.ComponentType<unknown>);
