import { memoize } from 'lodash';
import LRU from 'lru-cache';

import { HighchartsPoint } from '@/lib/highcharts/config-helpers';

export type Filter = <T extends { y: number | null }>(range: T[]) => T[];

// NOTE: Since JS doesn't expose memory addresses, we cannot easily determine if
// an object has changed. However, we can store such unique items in a WeakMap
// to implement such functionality. The id is incremented everytime the object's
// reference changes. This is useful for caching purposes, e.g. in
// memoizedFiltering below.
const id = (() => {
  let currentId = 0;
  const map = new WeakMap<object, number>();

  return (object: object) => {
    if (!map.has(object)) {
      map.set(object, ++currentId);
    }

    return `${map.get(object)}`;
  };
})();

export const memoizedFiltering = memoize<
  (points: HighchartsPoint[], filters: Filter[], cacheIdentifiers?: Record<string, string>) => HighchartsPoint[]
>(
  (points, filters) => {
    return filters.reduce((filteredPoints, filter) => {
      return filter(filteredPoints);
    }, points);
  },
  (points, filters, cacheIdentifiers = {}) => {
    const [point] = points;

    return [
      id(point || {}),
      filters.length,
      Object.entries(cacheIdentifiers)
        .map(([key, value]) => `${key}:${value}`)
        .join('-'),
    ].join('|');
  },
);

const lru = new LRU<string, HighchartsPoint[]>({ max: 50 });

// NOTE: memoize expects a different interface than what lru-cache provides.
const defaultSetter = lru.set;
// @ts-ignore
lru.set = function (key, value, maxAge) {
  return defaultSetter.apply(this, [key, value, maxAge]) && this;
};
// @ts-ignore
memoizedFiltering.cache = lru;

export const midRangeFilter =
  (size: number): Filter =>
  (range) => {
    const globalMax = range.reduce((max, { y }) => Math.max(max, y || 0), 1);

    return range.reduce<typeof range>((averaged, value, index) => {
      if (typeof value.y !== 'number') {
        averaged.push(value);
        return averaged;
      }

      const start = Math.max(0, index - size);
      const end = Math.min(range.length, index + size + 1);

      const window = range
        .slice(start, end)
        .filter(({ y }) => typeof y === 'number')
        .map(({ y }) => y || 0);

      const min = Math.min(...window);
      const max = Math.max(...window);

      const definiteValue = value.y || 0;

      const midrange = (min + max) / 2;

      const significant = Math.abs(midrange - definiteValue) / globalMax > 0.2;

      averaged.push({
        ...value,
        y: significant ? value.y : midrange,
      });

      return averaged;
    }, []);
  };

export const movingAverage =
  (size: number): Filter =>
  (range) => {
    return range.reduce<typeof range>((averaged, value, index) => {
      if (typeof value.y !== 'number') {
        averaged.push(value);
        return averaged;
      }

      const start = Math.max(0, index - size);
      const end = Math.min(range.length, index + size + 1);

      const window = range
        .slice(start, end)
        .filter(({ y }) => typeof y === 'number')
        .map(({ y }) => y || 0);

      averaged.push({
        ...value,
        y: window.reduce((sum, value) => sum + value, 0) / window.length,
      });

      return averaged;
    }, []);
  };

// NOTE: Mixing filters into the second iteration of the samples becomes fairly
// complex, because we may potentially have a window that spans into the future,
// to a sample point that is yet to be processed. We could introduce a lag that
// is equivalent to half the window size, but that shifts everything. The
// following commented functions allow to be run in this fashion, however, the
// lag must be determined by the caller (and range represent that shift).
//
// The caller would do something like the following (it is not tested, read it as pseudo code):
// const composers = [
//   voidProcessor({ average: isMeasurement, now }),
//   filters.reduce((processor, filter) => {
//     return (storedPoints, point, index, points) => {
//       const processedPoints = processor(point, index, points) as HighchartsPoint[];
//       const processedPointIndex = processedPoints.findIndex((point) => typeof point.y === 'number');
//       return processedPoints.splice(processedPointIndex, 1, filter(processedPoints[processedPointIndex], index - Math.ceil(windowSize / 2), storedPoints));
//     };
//   }, sampleProcessor({ scale, average: isMeasurement, perPointSpan })),
//   varianceProcessor({ isMeasurement }),
// ];
//
// The smart thing about it, is that we can do all processing steps in linear
// time (as I think maybe the filters we wish to use can utilize the cumsum
// structure) :pog:

// export const midRangeFilter = (size: number) => <T extends { y: number | null }>(point: T, index: number, range: T[]) => {
//   const globalMax = range.reduce((max, { y }) => Math.max(max, y || 0), 1);

//   return range.reduce<T[]>((averaged, value, index) => {
//     const start = Math.max(0, index - size);
//     const end = Math.min(range.length, index + size + 1);

//     const window = range.slice(start, end).map(({ y }) => y || 0);

//     const min = Math.min(...window);
//     const max = Math.max(...window);

//     const definiteValue = value.y || 0;

//     const midrange = (min + max) / 2;

//     const significant = Math.abs(midrange - definiteValue) / globalMax > 0.2;

//     averaged.push({
//       ...value,
//       y: significant ? value.y : midrange,
//     });

//     return averaged;
//   }, []);
// };

// export const movingAverage = (size: number) => <T extends { y: number | null }>(point: T, index: number, range: T[]) => {
//   return range.reduce<T[]>((averaged, value, index) => {
//     const start = Math.max(0, index - size);
//     const end = Math.min(range.length, index + size + 1);

//     const window = range.slice(start, end).map(({ y }) => y || 0);

//     averaged.push({
//       ...value,
//       y: window.reduce((sum, value) => sum + value, 0) / window.length,
//     });

//     return averaged;
//   }, []);
// };
